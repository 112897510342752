@font-face {
  font-family: "goin";
  src: url("https://cdn.goin.cloud/assets/fonts/goin.ttf");
}
@font-face {
  font-family: "GreycliffCF-Bold";
  src: url("https://cdn.goin.cloud/assets/fonts/GreycliffCF-Bold.otf");
}
@font-face {
  font-family: "GreycliffCF-Medium";
  src: url("https://cdn.goin.cloud/assets/fonts/GreycliffCF-Medium.otf");
}
@font-face {
  font-family: "GreycliffCF-DemiBold";
  src: url("https://cdn.goin.cloud/assets/fonts/GreycliffCF-DemiBold.ttf");
}
@font-face {
  font-family: "GreycliffCF-Regular";
  src: url("https://cdn.goin.cloud/assets/fonts/GreycliffCF-Regular.otf");
}
@font-face {
  font-family: "TT Hoves Regular";
  src: url("https://cdn.goin.cloud/assets/fonts/TT Hoves Regular.otf");
}
@font-face {
  font-family: "TT Hoves Medium";
  src: url("https://cdn.goin.cloud/assets/fonts/TT Hoves Medium.otf");
}
@font-face {
  font-family: "TT Hoves DemiBold";
  src: url("https://cdn.goin.cloud/assets/fonts/TT Hoves DemiBold.otf");
}
@font-face {
  font-family: "PerfectlyNineties-Regular";
  src: url("https://cdn.goin.cloud/assets/fonts/PerfectlyNineties-Regular.ttf");
}
@font-face {
  font-family: "PerfectlyNineties-Italic";
  src: url("https://cdn.goin.cloud/assets/fonts/PerfectlyNineties-Italic.ttf");
}
@font-face {
  font-family: "NinetiesHeadliner-Regular";
  src: url("https://cdn.goin.cloud/assets/fonts/NinetiesHeadliner-Regular.ttf");
}
